<template>
  <b-button type="button" :variant="variant" :id="`traBtn`+suffix+id" :to="to" v-if="show">
    <slot />
  </b-button>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: 'primary'
    },
    suffix: {
      type: String,
      default: ''
    },
    id: {
      type: [String, Number],
      default: '0'
    },
    to: {
      type: [String, Object],
      default: null
    },
    show: {
      type: Boolean,
      default: true
    },
  },
}
</script>
